import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["maskInput"];
  static values = { pattern: String };

  connect() {
    this.initMask();
  }

  initMask = () => {

    const SPMaskBehavior = function (val) {
      return val.replace(/\D/g, '').length === 11 ? '(00) 00000-0000' : '(00) 0000-00009';
    }
    
    const spOptions = {
      onKeyPress: function(val, e, field, options) {
          field.mask(SPMaskBehavior.apply({}, arguments), options);
        }
    };
    
    if (this.patternValue === 'phone_with_ddd') {
      $(this.maskInputTarget).mask(SPMaskBehavior, spOptions);
    } else {
      $(this.maskInputTarget).mask(this.patternValue);
    }
  }
}

