import { Controller } from "@hotwired/stimulus"
import Choices from "choices.js";

export default class extends Controller {
  static targets = ["choiceInput"];

  connect() {
    if (!this.choiceInputTargets[0].classList.contains("choices__input")) {
      this.initializeChoices();
    }
  }

  initializeChoices() {
    const options = {
      searchEnabled: false,
      itemSelectText: '',
      shouldSort: false,
      position: 'bottom',
      removeItemButton: true,
      placeholder: true
    };

    
    $(this.choiceInputTargets).each((index, element) => {
      new Choices(element, options);
    })
  }

  onSubmit = (e) => {
    e.preventDefault();
    
    let totalInValid = 0;
    
    $(this.choiceInputTargets).each((index, element) => {
      let innerElement = element.parentNode;
      if (element.value === '' && element.classList.contains('required')) {
        totalInValid++;
        innerElement.classList.add('is-invalid');
      } else {
        innerElement.classList.remove('is-invalid');
      }
    });

    if (totalInValid === 0) {
      e.target.form.requestSubmit();
    }
  }
}
